import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Banner from "./Banner";
import MainArea from "./MainArea";
import LeftMenu from "./LeftMenu";

const drawerWidth = 240;

export default function Home() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Banner drawerWidth={drawerWidth} onToggle={handleDrawerToggle}/>
      <LeftMenu drawerWidth={drawerWidth} onToggle={handleDrawerToggle} mobileOpen={mobileOpen}/>
      <MainArea drawerWidth={drawerWidth}/>
    </Box>
  );
}
