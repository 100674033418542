import { useState } from "react";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { config } from "../config/constant";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

interface Props {
  setResult: (input:string, result:string) => void;
}

const ChatInputBox = styled(TextField)`
  font-size: 20px;
  min-height: 56px;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SubmitButton = styled(LoadingButton)`
  font-size: 18px;
`;

const InputBox = ({ setResult }: Props) => {
  const [textInput, setTextInput] = useState("");
  const [loading, setLoading] = useState(false);

  async function onSubmit(event: any) {
    let historyObject = {
      completion: "",
      result: "",
    };

    event.preventDefault();
    setLoading(true);

    historyObject.completion = textInput;

    try {
      const response = await fetch(`${config.url.API_URL}/api/completion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ completionText: textInput }),
      });

      const data = await response.json();
      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
      historyObject.result = data.result;
      setResult(textInput, data.result);
      setLoading(false);
      setTextInput("");
    } catch (error: any) {
      // Consider implementing your own error handling logic here
      console.error(error);
      alert(error.message);
    }
  }

  return (
    <Grid container spacing={2} className="input-field">
      <Grid item xs={2} md={2} />
      <Grid item xs={10} md={8}>
        <ChatInputBox
          type="text"
          color="primary"
          placeholder="Please input your query"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          multiline
          fullWidth
          InputProps={{ style: { fontSize: 20 } }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

export default InputBox;
