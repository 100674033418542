import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import InputBox from "./InputBox";
import ChatTextBox from "./ChatTextBox";

interface Props {
  drawerWidth: number;
}
interface HistoryData {
  completion: string;
  result: string;
}

const MainArea = ({ drawerWidth }: Props) => {
  const [result, setResult] = useState("");
  const [historyData, setHistoryData] = useState<HistoryData[]>([]);

  function getResult(input: string, result: string) {
    setResult(result);
    const historyObj: HistoryData = {
      completion: input,
      result: result,
    };
    setHistoryData([...historyData, historyObj]);
    console.log(historyData);
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      {historyData.map((item) => {
        return (
          <>
            <ChatTextBox isCompletion={true}>{item.completion}</ChatTextBox>{" "}
            <ChatTextBox isCompletion={false}>{item.result}</ChatTextBox>
          </>
        );
      })}
      <InputBox setResult={getResult}></InputBox>
    </Box>
  );
};

export default MainArea;
