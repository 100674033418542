import Typography from "@mui/material/Typography";

interface Props {
  children: string;
  isCompletion: boolean;
}

const ChatTextBox = ({ children, isCompletion = true }: Props) => {
  const bgColor = isCompletion ? "#e6ffe6" : "#cce6ff";
  const roleTxt = isCompletion ? "You" : "ChatGPT";
  return (
    <Typography paragraph style={{ backgroundColor: bgColor }}>
      <b>{roleTxt}</b>: <br /> {children}
    </Typography>
  );
};

export default ChatTextBox;
